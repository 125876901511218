<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="$route.params.accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">Account Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Realm overview</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1>
                <p class="text-caption text-center">Realm overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 px-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0"> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-app-bar color="indigo" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                        <v-app-bar-title>
                            Realm settings
                        </v-app-bar-title>
                        <!-- <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn icon color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                                < ! - - Publish - - >
                            </v-btn>
                        </template> -->
                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-app-bar>
                        <v-card-text>

                            <p class="text-overline mb-0 mt-5">Display Name</p>
                            <p class="mb-0 pb-0">
                                <span>{{ displayName }}</span>
                                <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn>
                            </p>
                            <p class="text-overline mb-0">Realm Alias</p>
                            <p class="mb-0 pb-0">
                                <span>{{ name }}</span>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                            <v-dialog v-model="dialogEditDisplayName" max-width="600">
                                <v-card elevation="4" class="pa-5">
                                    <v-card-text class="text-h6 pa-0 pb-3">Edit realm display name</v-card-text>
                                    <v-row justify="center" class="pt-3">
                                        <v-col cols="12">
                                            <div class="font-weight-light text-body-1">
                                                <p>This will be shown to users when they are logging in. If you leave it blank, the realm name will be shown instead.</p>
                                                <p>Some examples:</p>
                                                <ul>
                                                <li>Website domain, "example.com"</li>
                                                <li>Company name, "Example Company"</li>
                                                <li>Company name and resource name, "Example Customer Portal"</li>
                                                <li>Product or service name, "Example Starter Kit"</li>
                                                </ul>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-form @submit="editDisplayName" onSubmit="return false;" @keyup.enter.native="editDisplayName" class="mt-5">
                                        <v-text-field
                                            v-model="editableDisplayName"
                                            ref="editableDisplaynameInput"
                                            label="Realm display name"
                                            :rules="displayNameRules"
                                            outlined
                                        ></v-text-field>
                                        <v-card-actions>
                                            <v-row justify="center">
                                            </v-row>
                                        </v-card-actions>
                                    </v-form>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn elevation="4" class="blue white--text" @click="editDisplayName" :disabled="!isEditDisplayNameFormComplete">
                                            Save
                                        </v-btn>
                                        <v-btn text class="grey--text" @click="dialogEditDisplayName = false">Cancel</v-btn>
                                        <v-spacer/>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <!-- This controls whether users asked for their email address or pseudonym (username) as the first step of signing in -->
                            <p class="text-overline mb-0 mt-2">User Alias Mode</p>
                            <p class="mb-0 pb-0">
                                <span>{{ userAliasModeText }}</span>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>

                            <!-- TODO: Remove this in favor of the main website URL defined in the brandprofile, when that's available -->
                            <p class="text-overline mb-0 mt-5">Website URL</p>
                            <p class="mb-0 pb-0">
                                <span>{{ websiteURL }}</span>
                                <v-btn class="blue--text no-print" @click="dialogEditWebsiteURL = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn>
                            </p>
                            <v-dialog v-model="dialogEditWebsiteURL" max-width="600">
                                <v-card elevation="4" class="pa-5">
                                    <v-card-text class="text-h6 pa-0 pb-3">Edit realm website URL template</v-card-text>
                                    <v-row justify="center" class="pt-3">
                                        <v-col cols="12">
                                            <div class="font-weight-light text-body-1">
                                                <p>When users need to return to your website, this is where they will be redirected.</p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-form @submit="editWebsiteURL" onSubmit="return false;" @keyup.enter.native="editWebsiteURL" class="mt-5">
                                        <v-text-field
                                            v-model="editableWebsiteURL"
                                            ref="editableWebsiteURLInput"
                                            label="Realm website URL"
                                            :rules="urlRules"
                                            outlined
                                        ></v-text-field>
                                        <v-card-actions>
                                            <v-row justify="center">
                                            </v-row>
                                        </v-card-actions>
                                    </v-form>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn elevation="4" class="blue white--text" @click="editWebsiteURL" :disabled="!isEditWebsiteURLFormComplete">
                                            Save
                                        </v-btn>
                                        <v-btn text class="grey--text" @click="dialogEditWebsiteURL = false">Cancel</v-btn>
                                        <v-spacer/>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <p class="text-overline mb-0 mt-5">Login URL</p>
                            <p class="mb-0 pb-0">
                                <span>{{ loginURL }}</span>
                                <v-btn class="blue--text no-print" @click="dialogEditLoginURL = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn>
                            </p>
                            <v-dialog v-model="dialogEditLoginURL" max-width="600">
                                <v-card elevation="4" class="pa-5">
                                    <v-card-text class="text-h6 pa-0 pb-3">Edit realm login URL template</v-card-text>
                                    <v-row justify="center" class="pt-3">
                                        <v-col cols="12">
                                            <div class="font-weight-light text-body-1">
                                                <p>When users need to login, this is where they will be redirected.</p>
                                                <p>Some examples:</p>
                                                <ul>
                                                <li>Website domain and path, "https://example.com/login"</li>
                                                <li>Website subdomain, "https://login.example.com"</li>
                                                </ul>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-form @submit="editLoginURL" onSubmit="return false;" @keyup.enter.native="editLoginURL" class="mt-5">
                                        <v-checkbox
                                            v-model="editableIsCustomLoginURL"
                                        >
                                        <template #label>
                                            <span style="margin-left: 4px">Use custom login URL</span>
                                        </template>
                                        </v-checkbox>
                                        <v-text-field
                                            v-model="editableLoginURL"
                                            ref="editableLoginURLInput"
                                            label="Realm login URL"
                                            :rules="urlRules"
                                            v-show="editableIsCustomLoginURL"
                                            outlined
                                        ></v-text-field>
                                        <v-card-actions>
                                            <v-row justify="center">
                                            </v-row>
                                        </v-card-actions>
                                    </v-form>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn elevation="4" class="blue white--text" @click="editLoginURL" :disabled="!isEditLoginURLFormComplete">
                                            Save
                                        </v-btn>
                                        <v-btn text class="grey--text" @click="dialogEditLoginURL = false">Cancel</v-btn>
                                        <v-spacer/>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <!-- TODO: a little brandprofile preview with the colors and logo image -->
                            <p class="text-overline mb-0 mt-5">BrandProfile</p>
                            <p><router-link :to="{ name: 'realm-edit-brandprofile', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Set up a BrandProfile</router-link> to customize the authentication portal for this realm.
                            </p>
                            <template v-if="isPermitSystemAdmin">
                            <p class="text-overline mb-0 mt-5">Realm logo image (Service Administrators ONLY) <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/></p>
                            <p>Use the BrandProfile setup to customize the logo and colors for the realm. The logo image upload is an older method and will eventually be removed.</p>

                                <!-- problem with p tag is that it doesn't vertically align the image and the pencil icon -->
                                <!-- <p class="mb-0 pb-0">
                                    <RealmLogoImage :realmId="realm.id" :width="200" style="display: inline-block;"/>
                                    <v-btn class="blue--text no-print" @click="dialogEditRealmLogoImage = true" icon>
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                    </v-btn>
                                </p> -->
                                <v-row no-gutters align="center" style="width: 300px;">
                                    <RealmLogoImage :realmId="realm.id" :etag="realm.etag" :width="200" contain/>
                                    <v-btn class="blue--text no-print" @click="dialogEditRealmLogoImage = true" icon>
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                    </v-btn>
                                </v-row>
                                <!-- this one doesn't respect image width, but containing on height works, but the responsive image div expands to fill available space so it doesn't look good, the pencil icon is too far away -->
                                <!-- <v-row no-gutters align="center">
                                    <RealmLogoImage :realmId="realm.id" :height="50" contain/>
                                    <v-btn class="blue--text no-print" @click="dialogEditRealmLogoImage = true" icon>
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                    </v-btn>
                                </v-row> -->
                                <v-dialog v-model="dialogEditRealmLogoImage" max-width="600">
                                    <v-card elevation="4" class="pa-5">
                                    <v-card-text class="text-h6 pa-0 pb-3">Edit realm logo image</v-card-text>
                                        <v-row justify="start" class="px-5 pt-3" style="width: 300px;">
                                            <RealmLogoImage :realmId="realm.id" :etag="realm.etag" :width="200" contain/>
                                            <v-btn class="blue--text" @click="dialogUploadRealmLogoImage = true" text icon>
                                                <!-- Upload -->
                                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width size="1x"/>
                                            </v-btn>
                                            <v-btn class="red--text" @click="dialogDeleteRealmLogoImage = true" text icon v-if="realmLogoImage">
                                                <!-- Delete -->
                                                <font-awesome-icon :icon="['fas', 'trash']" fixed-width size="1x"/>
                                            </v-btn>
                                        </v-row>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn text class="black--text" @click="dialogEditRealmLogoImage = false">Close</v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogUploadRealmLogoImage" max-width="600">
                                    <v-card elevation="4">
                                        <v-toolbar short flat color="white">
                                            <v-toolbar-title>Upload realm logo image</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="mx-0 py-3 px-5">Image will be contained in a 300x300 square, but image does not need to be square.</v-card-text>
                                        <v-row justify="center" class="mx-5 pt-3">
                                            <v-file-input
                                                v-model="editableRealmLogoImage"
                                                show-size
                                                accept="image/png"
                                                :rules="avatarRules"
                                                label="Choose realm logo image (.png)"
                                                outlined>
                                            </v-file-input>
                                        </v-row>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn elevation="4" class="blue white--text" @click="editRealmLogoImage" :disabled="!editableRealmLogoImage">
                                                Save
                                            </v-btn>
                                            <v-btn text class="grey--text" @click="dialogUploadRealmLogoImage = false">Cancel</v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteRealmLogoImage" max-width="600">
                                    <v-card elevation="4" class="pa-5">
                                        <v-card-text class="text-h6 pa-0 pb-3">Delete realm logo image</v-card-text>
                                        <v-row justify="center" class="px-5 pt-3 my-5" style="width: 300px;">
                                            <RealmLogoImage :realmId="realm.id" :etag="realm.etag" :width="200" contain/>
                                        </v-row>
                                        <v-row justify="center" class="pb-2">
                                            <v-btn elevation="4" class="red white--text" @click="deleteRealmLogoImage">
                                                Delete
                                            </v-btn>
                                            <v-btn text class="grey--text" @click="dialogDeleteRealmLogoImage = false">Cancel</v-btn>
                                        </v-row>
                                    </v-card>
                                </v-dialog>
                            </template>

                            <p class="text-overline mb-0 mt-5">LoginShield</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'realm-loginshield', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Manage LoginShield settings</router-link>
                            </p>
                            <p class="text-overline mb-0 mt-5">API Clients</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'realm-search-client', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">See clients</router-link>
                            </p>

                            <!-- TODO: add this section on allowed protocols that will affect all clients; implement enforcement on the server when a new client is created , or an existing client is edited, to ensure the origin URL has an approved protocol -->
                            <!-- <p class="text-overline mb-0 mt-8">Security</p>
                            <p>Allowed origin protocols</p>
                            <p class="grey--text text--darken-1 text-caption">By default, clients are required to provide <var>https</var> URLs for redirecting users after a profile request. However, when developing a client application it may be extremely inconvenient to set up <var>https</var>. You can choose to allow <var>http</var> in a development environment. We strongly recommend to <strong>not</strong> enable <var>http</var> in production.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelectMultiple :value="permitOriginProtocolList" :items="clientPermitOriginProtocolChoices" @input="saveClientPermitOriginProtocol" dense/>
                            </p> -->

                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="red--text">
                                Danger zone
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="font-size: 0.8em;">
                            <p class="mt-4 mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete-realm' }">How to delete this realm and related data</router-link>
                            </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import RealmLogoImage from '@/components/RealmLogoImage.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';
// import EditableTextSelectMultiple from '@/components/EditableTextSelectMultiple.vue';
import { isValidName, isValidURL } from '@/sdk/input';
import { USER_ALIAS_MODE_EMAIL, USER_ALIAS_MODE_PSEUDONYM } from '@/sdk/constants';

export default {
    components: {
        RealmLogoImage,
        AccessDeniedOverlay,
        RealmBar,
        // EditableTextSelectMultiple,
    },
    data: () => ({
        realm: null,
        serviceConfig: null,
        forbiddenError: false,
        // realm display name
        dialogEditDisplayName: false,
        editableDisplayName: null,
        displayNameRules: [
            (v) => isValidName(v) || 'Enter the realm display name',
        ],
        // realm logo
        dialogUploadRealmLogoImage: false,
        dialogEditRealmLogoImage: false,
        dialogDeleteRealmLogoImage: false,
        editableRealmLogoImage: [],
        avatarRules: [
            (v) => (v && v.size < 400 * 1024) || 'File size should be less than 400 KB!',
        ],
        // custom realm login and website URL
        dialogEditLoginURL: false,
        editableIsCustomLoginURL: null,
        editableLoginURL: null,
        dialogEditWebsiteURL: false,
        editableWebsiteURL: null,
        urlRules: [
            (v) => isValidURL(v) || 'Enter a valid URL',
        ],
        // brandprofile
        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        ...mapGetters({
            isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        displayName() {
            return this.realm?.display_name ?? this.realm?.alias ?? 'Unknown realm';
        },
        name() {
            return this.realm?.alias ?? 'Unknown realm';
        },
        loginURL() {
            return this.realm?.info?.login_url_template ?? this.serviceConfig?.login_url_template ?? 'Undefined';
        },
        websiteURL() {
            return this.realm?.info?.website_url ?? 'Undefined';
        },
        brandprofileAlias() {
            return this.realm?.info?.brandprofile_alias ?? ''; // '(Not connected)';
        },
        isEditDisplayNameFormComplete() {
            return this.editableDisplayName && this.editableDisplayName !== this.realm?.display_name;
        },
        isEditLoginURLFormComplete() {
            return this.editableLoginURL && this.editableLoginURL !== this.realm?.info?.login_url;
        },
        isEditWebsiteURLFormComplete() {
            return this.editableWebsiteURL && this.editableWebsiteURL !== this.realm?.info?.website_url;
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        realmLogoImage() {
            return false;
        },
        userAliasModeText() {
            let text;
            switch (this.realm?.user_alias_mode) {
            case USER_ALIAS_MODE_EMAIL:
                text = 'Email';
                break;
            case USER_ALIAS_MODE_PSEUDONYM:
                text = 'Pseudonym';
                break;
            default:
                text = this.realm.user_alias_mode;
                break;
            }
            return text;
        },
    },
    watch: {
        dialogEditDisplayName(value) {
            if (value) {
                this.editableDisplayName = this.displayName;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableDisplaynameInput'); }, 1);
                });
            }
        },
        dialogEditLoginURL(value) {
            if (value) {
                this.editableIsCustomLoginURL = typeof this.realm?.info?.login_url_template === 'string';
                this.editableLoginURL = this.realm?.info?.login_url_template ?? '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableLoginURLInput'); }, 1);
                });
            }
        },
        dialogEditWebsiteURL(value) {
            if (value) {
                this.editableWebsiteURL = this.realm?.info?.website_url ?? '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableWebsiteURLInput'); }, 1);
                });
            }
        },
        // $route(value, oldValue) {
        //     if (value.query.accountId !== oldValue.query.accountId) {
        //         this.accountId = value.query.accountId;
        //     }
        // },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadServiceConfig() {
            try {
                this.$store.commit('loading', { loadServiceConfig: true });
                const result = await this.$client.main().setting.search();
                console.log(`realm/dashboard.vue loadServiceConfig result: ${JSON.stringify(result)}`);
                if (result && Array.isArray(result.list)) {
                    const configMap = {};
                    result.list.forEach((item) => { configMap[item.name] = item.content; });
                    this.serviceConfig = configMap;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadServiceConfig failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load service settings', message });
            } finally {
                this.$store.commit('loading', { loadServiceConfig: false });
            }
        },
        async editDisplayName() {
            try {
                this.$store.commit('loading', { editDisplayName: true });
                const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { display_name: this.editableDisplayName });
                if (isEdited) {
                    this.realm.display_name = this.editableDisplayName;
                    this.dialogEditDisplayName = false;
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Updated display name' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
                }
            } catch (err) {
                console.error('editDisplayName failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update display name' });
            } finally {
                this.$store.commit('loading', { editDisplayName: false });
            }
        },
        async editLoginURL() {
            try {
                this.$store.commit('loading', { editLoginURL: true });
                if (this.editableIsCustomLoginURL && this.editableLoginURL && isValidURL(this.editableLoginURL)) {
                    const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { login_url: this.editableLoginURL });
                    if (isEdited) {
                        const info = this.realm.info ?? {};
                        info.login_url = this.editableLoginURL;
                        this.$set(this.realm, 'info', info);
                        this.dialogEditLoginURL = false;
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Updated login URL' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to set custom login URL' });
                    }
                } else {
                    const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { action: 'reset-login-url' });
                    if (isEdited) {
                        const info = this.realm.info ?? {};
                        info.login_url = null;
                        this.$set(this.realm, 'info', info);
                        this.dialogEditLoginURL = false;
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Reset login URL' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to reset login URL' });
                    }
                }
            } catch (err) {
                console.error('editLoginURL failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to change login URL setting' });
            } finally {
                this.$store.commit('loading', { editLoginURL: false });
            }
        },
        async editWebsiteURL() {
            try {
                this.$store.commit('loading', { editWebsiteURL: true });
                if (this.editableWebsiteURL && isValidURL(this.editableWebsiteURL)) {
                    const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { website_url: this.editableWebsiteURL });
                    if (isEdited) {
                        const info = this.realm.info ?? {};
                        info.website_url = this.editableWebsiteURL;
                        this.$set(this.realm, 'info', info);
                        this.dialogEditWebsiteURL = false;
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Updated website URL' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to set custom website URL' });
                    }
                } else {
                    const isEdited = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.$route.params.realmId }, { action: 'reset-website-url' });
                    if (isEdited) {
                        // this.realm.info ??= {};
                        // this.realm.info.website_url = null;
                        const info = this.realm.info ?? {};
                        info.website_url = null;
                        this.$set(this.realm, 'info', info);
                        this.dialogEditWebsiteURL = false;
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Reset website URL' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to reset website URL' });
                    }
                }
            } catch (err) {
                console.error('editWebsiteURL failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to change website URL setting' });
            } finally {
                this.$store.commit('loading', { editWebsiteURL: false });
            }
        },
        async editRealmLogoImage() {
            /*
            See the note about editableRealmLogoImage in the 'data' section; because we are
            using a single-file input, the value of editableRealmLogoImage is set to a single
            File object by the time this method is called.
            */
            // console.log('editableRealmLogoImage: %o', this.editableRealmLogoImage); // {name: "icon.png", lastModified: 1545595273000, lastModifiedDate: a Date object, size: 8887, ... }
            if (this.editableRealmLogoImage.size > 0) {
                try {
                    this.$store.commit('loading', { editRealmLogoImage: true });
                    // https://developer.mozilla.org/en-US/docs/Web/API/FormData
                    const formData = new FormData();
                    formData.append('file', this.editableRealmLogoImage);
                    const result = await this.$client.account(this.$route.params.accountId).realm.editImage({ id: this.$route.params.realmId, label: 'logo' }, formData);
                    // await dispatch('loadAccount'); // TODO: reload just the affected account
                    if (result && result.isEdited) {
                        this.realm.etag = Date.now(); // notify realm logo image component to reload the image
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Updated image' });
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update image' });
                    }
                } catch (err) {
                    console.log('editRealmLogoImage failed', err);
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update image' });
                } finally {
                    this.$store.commit('loading', { editRealmLogoImage: false });
                    this.dialogUploadRealmLogoImage = false;
                    this.editableRealmLogoImage = [];
                }
            }
        },
        async deleteRealmLogoImage() {
            try {
                this.$store.commit('loading', { deleteRealmLogoImage: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.deleteImage({ id: this.$route.params.realmId });
                // await dispatch('loadAccount'); // TODO: reload just the affected account
                if (result && result.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Deleted image' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to delete image' });
                }
            } catch (err) {
                console.log('deleteRealmLogoImage failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to delete image' });
            } finally {
                this.$store.commit('loading', { deleteRealmLogoImage: false });
                this.dialogDeleteRealmLogoImage = false;
            }
        },
    },
    mounted() {
        this.loadRealm();
        this.loadServiceConfig();
    },
};
</script>
