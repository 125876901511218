const USER_ALIAS_MODE_EMAIL = 'email';
const USER_ALIAS_MODE_PSEUDONYM = 'pseudonym';
const USER_ALIAS_MODE_LIST = [
    USER_ALIAS_MODE_EMAIL,
    USER_ALIAS_MODE_PSEUDONYM,
];

export {
    USER_ALIAS_MODE_EMAIL,
    USER_ALIAS_MODE_PSEUDONYM,
    USER_ALIAS_MODE_LIST,
};
